@font-face {
    font-family: Patron;
    src: url(./fonts/Patron-Regular.otf) format('opentype');
}

@font-face {
    font-family: PatronBold;
    src: url(./fonts/Patron-Bold.otf) format('opentype');
}

html {
    min-height: 100%;
    background-color: #00f5c8;
    background-image: url('./halftone-bg-lo.svg');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}

body {
    height: 100%;
    color: #1e3c5a;
    background: none;
    font-family: 'Patron', Helvetica, sans-serif;
}

p {
    font-size: 1.5rem;
    line-height: 1.25;
}

.text-yellow {
    color: #ffff32;
}

button:focus, input[type=checkbox]:focus, .btn:focus {
    box-shadow: none !important;
    outline: none !important;
    border-color: #1e3c5a;
}

.btn {
    padding-top: 0.65rem;
    padding-bottom: 0.40rem;
}

.btn-primary, .btn-primary:hover, .btn-primary:focus .btn-primary.active:focus {
    background-color: #1e3c5a;
    color: white;
    border: none;
}

.btn-primary:disabled {
    background-color: #156a75;
    border: none;
}

.btn-outline-primary {
    color: #1e3c5a;
    border-color: #1e3c5a;
    border-color: #1e3c5a80;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:focus .btn-outline-primary.active:focus {
    background-color: #1e3c5a;
    border-color: #1e3c5a;
    border-color: #1e3c5a80;
}

.btn-outline-primary:hover {
    background-color: transparent;
    color: #1e3c5a;
    border-color: #1e3c5a;
    border-color: #1e3c5a80;
}

.btn-amount {
    height: 4rem;
    width: 4rem;
    background-color: white;
    border: none;
    font-size: 1.9rem;
    border-radius: 0.4rem;
    color: #1e3c5a;
}

.btn-amount:hover {
    background-color: lightcyan;
    color: #1e3c5a;
}

.btn-amount.active {
    color: #1e3c5a;
    background-color: #50b4ff;
}

.alert-danger {
    color: #1e3c5a;
    background-color: #ffaabe;
}

.form-control {
    color: #1e3c5a;
    border-radius: 0.4rem;
    height: 3rem;
    padding-top: 0.55rem;
}

.input-group-text {
    color: #1e3c5a;
    height: 3rem;
    padding-top: 0.68rem;
}

.form-control:focus {
    box-shadow: none !important;
    outline: none !important;
    border-color: #1e3c5a;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #ffff32;
}

.custom-control-input:checked~.custom-control-label::before {
    color: red;
    background-color: #ffff32;
    border-color: #1e3c5a;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231e3c5a' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

@media (min-width: 768px) {
    html {
        background-image: none;
    }
}